






































































































































































































































.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.framePage-body{
  .framePage-scroll{
    display: block !important;
    overflow: auto;

  }
}
// 对话框
.imgDialog{
  .detail{
    display: flex;
    .left{
      width: 150px;
      // height: 150px;
      flex-shrink: 0;
      .el-image{
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .right{
      margin-left: 20px;
      flex-grow: 1;
      .el-button{
        float: right;
      }
    }
  }
  .originalImg{
    margin-top: 20px;
    width: 100%;
    .el-image{
      display: block;
      width: 100%;
    }
  }
}
